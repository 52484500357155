<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="admin.includes(this.$user.details().type)"
							class="border-top-0">
							{{ translate('created_by') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('full_name') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('email') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('mobile_number') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('username') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('url') }}
						</th>
						<th
							v-if="[statuses.expired, statuses.active].includes(status)"
							class="border-top-0 pointer"
							@click="sortByField('expiration_date')">
							{{ translate(status === statuses.expired ? 'expired_at' : 'expires_at') }}
							<sort field="expiration_date" />
						</th>
						<th
							v-if="status === statuses.used"
							class="border-top-0 pointer"
							@click="sortByField('used_at')">
							{{ translate('used_date') }}
							<sort field="used_date" />
						</th>
						<th
							v-if="status === statuses.used"
							class="border-top-0">
							{{ translate('used_by') }}
						</th>
						<th
							v-if="status === statuses.active"
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							<div class="row no-gutters flex-nowrap">
								<div
									style="width: 60px;"
									class="col-auto">
									<span
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.attributes.sponsor_uid.toString() })">
										{{ item.attributes.sponsor_uid }}
									</span>
								</div>
								<div class="pl-2 col">
									{{ item.attributes.sponsor_username }}
								</div>
							</div>
						</td>
						<td class="align-middle">
							{{ item.attributes.full_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.user_information.personal_information.email }}
						</td>
						<td class="align-middle">
							{{ item.attributes.user_information.personal_information.mobile_number }}
						</td>
						<td class="align-middle">
							{{ item.attributes.user_information.personal_information.username }}
						</td>
						<td class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/header/${item.attributes.country ? item.attributes.country.toUpperCase() : 'default'}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ item.attributes.url }}
						</td>
						<td
							v-if="[statuses.expired, statuses.active].includes(status)"
							class="align-middle">
							{{ item.attributes.expiration_date.date ? $moment(item.attributes.expiration_date.date).format(dateFormat) : $moment(item.attributes.expiration_date).format(dateFormat) }}
						</td>
						<td
							v-if="status === statuses.used"
							class="align-middle">
							{{ item.attributes.used_date && item.attributes.used_date.date ? $moment(item.attributes.used_date.date).format(dateFormat) : (item.attributes.used_date ? $moment(item.attributes.used_date).format(dateFormat) : translate('NA')) }}
						</td>
						<td
							v-if="status === statuses.used"
							class="align-middle">
							<div class="row no-gutters flex-nowrap">
								<div
									style="width: 60px;"
									class="col-auto">
									<span class="badge badge-primary">
										{{ item.attributes.used_by_id }}
									</span>
								</div>
								<div class="pl-2 col">
									{{ item.attributes.used_by_username }}
								</div>
							</div>
						</td>
						<td
							v-if="status === statuses.active"
							class="align-middle text-center">
							<b-button
								v-clipboard="() => item.attributes.url"
								v-b-tooltip.hover
								:title="translate('copy_link')"
								class="btn mx-1 bg-primary-alt"
								@click="copy()">
								<i class="fas fa-copy" />
							</b-button>
							<b-button
								v-if="$can('custom_carts', 'view')"
								v-b-tooltip.hover
								:title="translate('view')"
								class="btn mx-1 bg-primary-alt"
								@click="$emit('show-products', item.id)">
								<i
									class="fa fa-eye"
									aria-hidden="true" />
							</b-button>
							<b-button
								v-if="$can('custom_carts', 'delete')"
								v-b-tooltip.hover
								:title="translate('delete')"
								class="btn mx-1 bg-primary-alt"
								@click="deleteCustomCart(item.id)">
								<i class="fas fa-trash-alt" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Grids, CustomCarts as CustomCartsMessages, Countries } from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { CUSTOM_CARTS_STATUSES } from '@/settings/CustomCarts';
import { admin } from '@/settings/Roles';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import CustomCarts from '@/util/CustomCarts';

export default {
	name: 'CustomCartList',
	messages: [Grids, CustomCartsMessages, Countries],
	mixins: [FiltersParams, DashboardRedirect],
	props: {
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			customCarts: new CustomCarts(),
			removeCustomCart: new CustomCarts(),
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			statuses: CUSTOM_CARTS_STATUSES,
			admin,
		};
	},
	computed: {
		loading() {
			return !!this.customCarts.data.loading;
		},
		pagination() {
			return this.customCarts.data.pagination;
		},
		data() {
			try {
				const { data } = this.customCarts.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.$route.query.status = this.status;
		this.customCarts.getCustomCarts();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters, status: this.status };

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.customCarts.getCustomCarts(options);
		},
		copy() {
			this.alert.toast('success', this.translate('link_copied'));
		},
		deleteCustomCart(id) {
			const trans = {
				title: this.translate('delete_custom_cart'),
				text: this.translate('delete_custom_cart_message'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				this.removeCustomCart.deleteCustomCart(id).then(() => {
					try {
						this.alert.toast('success', this.translate('custom_cart_deleted'));
					} finally {
						this.customCarts.getCustomCarts();
					}
				});
			}).catch(() => {});
		},
	},
};
</script>
